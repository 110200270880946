import cssVars from 'css-vars-ponyfill';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import ResizeObserver from 'resize-observer-polyfill';

import 'core-js/modules/es.object.assign';
import './polyfills';

import {
  IN_VIEW_CLASS_NAME,
  IN_VIEW_ENTER_CLASS_NAME,
} from '../constants';

import BookingRouter from './booking/router';
import { BOOKING_SECTION_ID } from './booking/router/constants';
import { connectRouter } from './ecommerce/ecwid/Routers';
import browser from './helpers/browser';
import getStateValue from './helpers/getStateValue';
import ieChangeCssVar from './helpers/ieChangeCssVar';
import inView from './helpers/inView';
import normilizeCSSFonts from './helpers/normilizeCSSFonts';
import { setBodyColorClasses, setBodyHasScroll } from './helpers/setBodyClasses';
import initAnalytics from './implementation/Analytics';
import handleAnchors from './implementation/Anchor';
import BackToTop from './implementation/BackToTop';
import disableBackupLinks from './implementation/BackupLinks';
import BlogPost from './implementation/Blog/Post';
import BlogSocialButtons from './implementation/Blog/SocialButtons/SocialButtons';
import { BookingProductWidget } from './implementation/Booking';
import CartButton from './implementation/CartButton';
import CookieBanner from './implementation/CookieBannerManager';
import Countdown from './implementation/Countdown';
import Disqus from './implementation/Disqus';
import ProductsWidget from './implementation/Ecommerce/ecwid/custom/ProductsWidget';
import EmbedCode from './implementation/EmbedCode';
import Facebook from './implementation/Facebook';
import Form from './implementation/Form';
import Gallery from './implementation/Gallery';
import GoogleMap from './implementation/GoogleMap';
import Header from './implementation/Header';
import logoErrorHandler from './implementation/Image/ErrorHandlers/logoErrorHandler';
import {
  fixContainImages,
  fixSvgImages,
} from './implementation/Image/utils';
import Instagram from './implementation/Instagram';
import ItemsView from './implementation/ItemsView';
import lazyLoad from './implementation/LazyLoad';
import Panel from './implementation/Panel';
import handleParallax from './implementation/Parallax';
import Photo from './implementation/Photo';
import PinterestButton from './implementation/PinterestButton';
import initPremiumFeatures from './implementation/PremiumFeatures';
import Promotion from './implementation/Promotion';
import SectionWrapper from './implementation/Section';
import SharingButtons from './implementation/SharingButtons';
import Slider from './implementation/Slider';
import SocialButtons from './implementation/SocialButtons';
import SSLSeal from './implementation/SSLSeal';
import SVGLoader from './implementation/SVGLoader';
import Table from './implementation/Table';
import TemplatePreview from './implementation/TemplatePreview';
import Twitter from './implementation/Twitter';
import Video from './implementation/Video';
import { createDeviceObserver, initDeviceObserver } from './observer/deviceObserver';
import {
  createResizeObserver,
  initResizeObserver,
} from './observer/resizeObserver';
import dom from './wrapper/DomWrapper';
import {
  BLOG_POST,
  BLOG_POST_SELECTOR,
  BLOG_SOCIAL_WIDGET_NAME,
  BLOG_SOCIAL_WIDGET_SELECTOR,
  BOOKING_PRODUCT_NAME,
  BOOKING_PRODUCT_SELECTOR,
  CART_BUTTON_NAME,
  CART_BUTTON_SELECTOR,
  COOKIE_NOTICE_NAME,
  COOKIE_NOTICE_SELECTOR,
  COUNTDOWN_NAME,
  COUNTDOWN_NAME_SELECTOR,
  DISQUS_NAME,
  DISQUS_SELECTOR,
  ECOM_PRODUCT_NAME,
  ECOM_PRODUCT_SELECTOR,
  EMBED_CODE_WIDGET_NAME,
  EMBED_CODE_WIDGET_SELECTOR,
  FACEBOOK_NAME,
  FACEBOOK_SELECTOR,
  FORM_WIDGET_NAME,
  FORM_WIDGET_SELECTOR,
  GALLERY_WIDGET_NAME,
  GALLERY_WIDGET_SELECTOR, GOOGLE_MAP_NAME, GOOGLE_MAP_SELECTOR,
  HEADER_WIDGET_NAME,
  HEADER_WIDGET_SELECTOR,
  INSTAGRAM_WIDGET_NAME,
  INSTAGRAM_WIDGET_SELECTOR,
  ITEMS_VIEW_NAME,
  ITEMS_VIEW_SELECTOR,
  PANEL_NAME,
  PANEL_WIDGET_SELECTOR,
  PHOTO_CODE_WIDGET_NAME,
  PHOTO_CODE_WIDGET_SELECTOR,
  PINTEREST_BUTTON_NAME,
  PINTEREST_BUTTON_SELECTOR,
  POST_IMAGE_NAME,
  PROMOTION_NAME,
  PROMOTION_SELECTOR,
  SECTION_NAME,
  SECTION_NEW_NAME,
  SECTION_SELECTOR,
  SHARING_BUTTONS_WIDGET_NAME,
  SHARING_BUTTONS_WIDGET_SELECTOR,
  SLIDER_WIDGET_NAME,
  SLIDER_WIDGET_SELECTOR, SOCIAL_BUTTON_NAME, SOCIAL_BUTTON_SELECTOR,
  SSL_SEAL_NAME,
  SSL_SEAL_SELECTOR,
  SVG_LOADER_WIDGET_NAME,
  SVG_LOADER_WIDGET_SELECTOR,
  TABLE_NAME,
  TABLE_WRAP_SELECTOR,
  TWITTER_WIDGET_NAME,
  TWITTER_WIDGET_SELECTOR,
  VIDEO_WIDGET_NAME,
  VIDEO_WIDGET_SELECTOR,
  WEBSITE_ANIMATION,
} from './constants';

const WIDGETS_MAP = new Map([
  [
    SHARING_BUTTONS_WIDGET_NAME,
    new SharingButtons(SHARING_BUTTONS_WIDGET_SELECTOR),
  ],
  [
    GALLERY_WIDGET_NAME,
    new Gallery(GALLERY_WIDGET_SELECTOR),
  ],
  [
    EMBED_CODE_WIDGET_NAME,
    new EmbedCode(EMBED_CODE_WIDGET_SELECTOR),
  ],
  [
    HEADER_WIDGET_NAME,
    new Header(HEADER_WIDGET_SELECTOR),
  ],
  [
    PHOTO_CODE_WIDGET_NAME,
    new Photo(PHOTO_CODE_WIDGET_SELECTOR),
  ],
  [
    INSTAGRAM_WIDGET_NAME,
    new Instagram(INSTAGRAM_WIDGET_SELECTOR),
  ],
  [
    VIDEO_WIDGET_NAME,
    new Video(VIDEO_WIDGET_SELECTOR),
  ],
  [
    SLIDER_WIDGET_NAME,
    new Slider(SLIDER_WIDGET_SELECTOR),
  ],
  [
    TWITTER_WIDGET_NAME,
    new Twitter(TWITTER_WIDGET_SELECTOR),
  ],
  [
    SVG_LOADER_WIDGET_NAME,
    new SVGLoader(SVG_LOADER_WIDGET_SELECTOR),
  ],
  [
    FORM_WIDGET_NAME,
    new Form(FORM_WIDGET_SELECTOR),
  ],
  [
    BLOG_SOCIAL_WIDGET_NAME,
    new BlogSocialButtons(BLOG_SOCIAL_WIDGET_SELECTOR),
  ],
  [
    BLOG_POST,
    new BlogPost(BLOG_POST_SELECTOR),
  ],
  [
    ITEMS_VIEW_NAME,
    new ItemsView(ITEMS_VIEW_SELECTOR),
  ],
  [
    ECOM_PRODUCT_NAME,
    new ProductsWidget(ECOM_PRODUCT_SELECTOR),
  ],
  [
    COOKIE_NOTICE_NAME,
    new CookieBanner(COOKIE_NOTICE_SELECTOR),
  ],
  [
    PROMOTION_NAME,
    new Promotion(PROMOTION_SELECTOR),
  ],
  [
    FACEBOOK_NAME,
    new Facebook(FACEBOOK_SELECTOR),
  ],
  [
    CART_BUTTON_NAME,
    new CartButton(CART_BUTTON_SELECTOR),
  ],
  [
    SOCIAL_BUTTON_NAME,
    new SocialButtons(SOCIAL_BUTTON_SELECTOR),
  ],
  [
    PINTEREST_BUTTON_NAME,
    new PinterestButton(PINTEREST_BUTTON_SELECTOR),
  ],
  [
    DISQUS_NAME,
    new Disqus(DISQUS_SELECTOR),
  ],
  [
    SSL_SEAL_NAME,
    new SSLSeal(SSL_SEAL_SELECTOR),
  ],
  [
    SECTION_NAME,
    new SectionWrapper(SECTION_SELECTOR),
  ],
  [
    SECTION_NEW_NAME,
    new SectionWrapper(SECTION_SELECTOR),
  ],
  [
    PANEL_NAME,
    new Panel(PANEL_WIDGET_SELECTOR),
  ],
  [
    COUNTDOWN_NAME,
    new Countdown(COUNTDOWN_NAME_SELECTOR),
  ],
  [
    BOOKING_PRODUCT_NAME,
    new BookingProductWidget(BOOKING_PRODUCT_SELECTOR),
  ],
  [
    GOOGLE_MAP_NAME,
    new GoogleMap(GOOGLE_MAP_SELECTOR),
  ],
  [
    TABLE_NAME,
    new Table(TABLE_WRAP_SELECTOR),
  ],
]);

const ENABLED_WIDGET_LIST = new Set([
  SHARING_BUTTONS_WIDGET_NAME,
  GALLERY_WIDGET_NAME,
  EMBED_CODE_WIDGET_NAME,
  HEADER_WIDGET_NAME,
  PHOTO_CODE_WIDGET_NAME,
  INSTAGRAM_WIDGET_NAME,
  VIDEO_WIDGET_NAME,
  SLIDER_WIDGET_NAME,
  TWITTER_WIDGET_NAME,
  SVG_LOADER_WIDGET_NAME,
  FORM_WIDGET_NAME,
  ECOM_PRODUCT_NAME,
  COOKIE_NOTICE_NAME,
  BLOG_SOCIAL_WIDGET_NAME,
  POST_IMAGE_NAME,
  ITEMS_VIEW_NAME,
  PANEL_NAME,
  PROMOTION_NAME,
  FACEBOOK_NAME,
  CART_BUTTON_NAME,
  SOCIAL_BUTTON_NAME,
  PINTEREST_BUTTON_NAME,
  DISQUS_NAME,
  SSL_SEAL_NAME,
  BLOG_POST,
  SECTION_NAME,
  SECTION_NEW_NAME,
  BOOKING_PRODUCT_NAME,
  COUNTDOWN_NAME,
  GOOGLE_MAP_NAME,
  TABLE_NAME,
]);

const connectWebsiteAnimation = () => {
  const animation = getStateValue('settings.animation');

  if (!animation?.enabled
    || !animation?.value) return false;

  dom.addClass(dom.document.body, WEBSITE_ANIMATION[animation?.value]);
  inView({
    selector: `.${IN_VIEW_CLASS_NAME}`,
    onEnter: (el) => {
      dom.addClass(el, IN_VIEW_ENTER_CLASS_NAME);
    },
    once: true,
  });
};

const connectWidgets = () => {
  const widgets = dom.getCollection('.js-widget');
  const store = dom.getElement('._store');
  const booking = dom.getElement(`#${BOOKING_SECTION_ID}`);

  if (store) {
    const EcommerceType = get(store, 'dataset.type', 'ECWID_CUSTOM_PAGE_TYPE');
    const isDashStore = store?.dataset?.is_dash_store === 'true';
    const router = connectRouter(EcommerceType, { isDashStore });

    router.init();
  } else if (booking) {
    const router = new BookingRouter();

    router.init();
  }

  if (isEmpty(widgets)) return;
  if (isFunction(createResizeObserver)) createResizeObserver();
  if (isFunction(createDeviceObserver)) createDeviceObserver();

  const widgetList = [...widgets].reduce((acc, el) => {
    const widgetName = el.dataset.widget;

    if (!widgetName
      || acc.includes(widgetName)
      || !ENABLED_WIDGET_LIST.has(widgetName)) return acc;

    acc.push(widgetName);

    return acc;
  }, []);

  if (widgetList.length === 0) return;

  for (const widget of widgetList) {
    const init = WIDGETS_MAP.has(widget)
      ? WIDGETS_MAP.get(widget).init
      : () => null;

    init();
  }
};

const connectDynamic = async () => {
  const isTemplate = getStateValue('isTemplate', false);
  const isScreenshot = getStateValue('isScreenshot', false);

  try {
    lazyLoad();
    logoErrorHandler();
    setBodyColorClasses();

    if (!isTemplate) await initPremiumFeatures();
  } finally {
    if (!isTemplate && !isScreenshot) ieChangeCssVar();
    if (!isTemplate && !isScreenshot) BackToTop();

    disableBackupLinks();

    connectWidgets();
    fixSvgImages();
    fixContainImages();

    if (!isTemplate && !isScreenshot) await initAnalytics();

    const resizeObserver = new ResizeObserver(debounce(() => {
      setBodyHasScroll();
    }, 200));

    resizeObserver.observe(dom.document.body);

    if (isTemplate) TemplatePreview();

    handleParallax();
    connectWebsiteAnimation();

    normilizeCSSFonts();
    handleAnchors();
  }
};

// This logic for IE
// ToDO: IE

const connectDynamicIE = () => {
  cssVars({
    onlyLegacy: false,
    silent: true,
    onComplete(cssText, styleNodes, cssVariables) {
      set(dom.window, ['cssVarPoly', 'varsStorage', 'varsList'], cssVariables);
      connectDynamic();
    },
  });
};

dom.on(dom.document, 'DOMContentLoaded', browser.isIe() ? connectDynamicIE : connectDynamic);
dom.on(dom.window, 'resize', () => {
  if (isFunction(initResizeObserver)) initResizeObserver();
  if (isFunction(initDeviceObserver)) initDeviceObserver();
});
